.App{
  width: 100vw;
  height: 100vh;
}

body{
  margin: 0;
  padding: 0;
}

.sidebar{
  height: 100%;
  width: 260px;
}