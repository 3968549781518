
.App {
    text-align: center;
  }
  
.App-logo {
  pointer-events: none;
  width: 100px;
  height: 100px;
  position: absolute;              
  top: 40%;   
  left: 45%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 0.5s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.back {
  content: '';
}

.back::after {
  content: "";
  background-color: rgb(185, 185, 185);
  background-image: url('../resource/mosaic_channels.png');
  opacity: 0.2;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;   
}